<template>
  <section class="code-guide">
    <h2 class="code-guide__title">Зачем нужен UI-кит</h2>
    <p class="code-guide__content">
      UI-кит - это инструмент, который позволяет собирать страницу из уже готовых блоков. Его
      преимущество состоит в том, что программисту не приходится каждый раз стилизовать и верстать
      компоненты заново. Для этого и создан этот UI-кит, он очень сильно оптимизирует разработку
      проекта.
    </p>
    <h2 class="code-guide__title">Как пополнять UI-кит</h2>
    <ol class="code-guide__content">
      <li>
        Перед тем как начать верстать страницу для компонента, посмотрите нет ли такой страницы в
        UI-ките.
      </li>
      <li>
        Для начала нужно создать страницу для просмотра компонента. Создайте Vue.js файл в
        <b>src/modules/UiKit/pages/ComponentsPage/Components</b>
        и назовите его по типу
        <em>ComponentName.vue</em>
      </li>
      <li>
        Заполните его по примеру компонента <em>AopGuide.vue</em>, находящегося в
        <b>src/modules/UiKit/pages/ComponentsPage/Components/AopGuide.vue</b>
      </li>
      <li>
        Чтобы отображать вашу страницу в UI-ките, добавьте ее в children к route с name:
        'UIComponents'.
      </li>
      <li>
        В UI-ките появится ссылка на ваш компонент. Аналогичная процедура происходит с цветами,
        типографией и т.д.
      </li>
      <li>
        Создавайте компонент, учитывая все
        <a href="https://confluence-order.smw.team/display/AOP/Code+Style+AOP">правила</a>
        и делайте его таким образом, чтобы он был наиболее универсальный.
      </li>
      <li>
        Учитывайте все возможные варианты реализации, чтобы в дальнейшем вносить меньше правок.
      </li>
    </ol>
  </section>
</template>

<script>
export default {
  name: "GuidePage"
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.code-guide {
  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;
  }

  &__content {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
  }

  li {
    margin: 0 0 16px;
  }
}
</style>
